const useBuilder = () => {
  const buildPropsComponents = (data) => {
    const {
      acf: { wst_builder },
    } = data;

    const dataMappings = {
      wst_header_slider: headerData,
      wst_latest_properties: latestPropertiesData,
      wst_property_alert: alertPropertiesData,
      wst_testimonials: testimonialsData,
      wst_two_boxes: sellAndBuyData,
      wst_related_magazines: magazinesData,
      wst_our_network: joinStaffData,
      wst_banner: bannerData,
      wst_html: htmlData,
    };

    const componentsMappings = {
      wst_header_slider: 'cover',
      wst_latest_properties: 'latestProperties',
      wst_property_alert: 'alertProperty',
      wst_testimonials: 'testimonials',
      wst_two_boxes: 'sellAndBuy',
      wst_related_magazines: 'magazines',
      wst_our_network: 'joinStaff',
      wst_banner: 'banner',
      wst_html: 'html',
    };

    return wst_builder.map((item) => {
      const layout = item.acf_fc_layout;
      const dataFunction = dataMappings[layout];
      const nameComponent = componentsMappings[layout];
      if (dataFunction) {
        const props = dataFunction(item);
        return { props, ...{ type: nameComponent } };
      }
    });
  };

  const headerData = (data) => {
    const { wst_header_slider_title, wst_header_slider_image } = data;
    return {
      title: wst_header_slider_title || '',
      imgUrl: wst_header_slider_image || '',
    };
  };

  const latestPropertiesData = (data) => {
    const { wst_latest_properties_title, wst_latest_properties_type } = data;
    const title = wst_latest_properties_title || '';
    const typeId = wst_latest_properties_type || '';
    return {
      title: title,
      typeId: typeId,
    };
  };

  const alertPropertiesData = (data) => {
    const { wst_property_alert_title, wst_property_alert_description, wst_property_alert_button } =
      data;
    const title = wst_property_alert_title || '';
    const description = wst_property_alert_description || '';
    const buttonName = wst_property_alert_button || '';
    return {
      title: title,
      description: description,
      buttonName: buttonName,
    };
  };

  const testimonialsData = (data) => {
    const { wst_testimonials_title, wst_testimonials_repeater } = data;
    const title = wst_testimonials_title || '';
    const AllTestimonials = wst_testimonials_repeater || [];
    const testimonials = AllTestimonials.map((testimonial) => {
      const { writer, wst_testimonials_repeater_image, wst_testimonials_repeater_text } =
        testimonial;
      const imgUrl = wst_testimonials_repeater_image || '';
      return {
        author: writer || '',
        imgUrl: imgUrl,
        comment: wst_testimonials_repeater_text || '',
      };
    });
    return {
      title: title,
      comments: testimonials,
    };
  };

  const sellAndBuyData = (data) => {
    const { wst_two_boxes_group1, wst_two_boxes_group2 } = data;
    const buy = wst_two_boxes_group1 || {};
    const sell = wst_two_boxes_group2 || {};
    const group = [buy, sell];
    return group.map((item, index) => {
      const number = index + 1;
      const buttonKey = `wst_two_boxes_button${number}`;
      const descriptionKey = `wst_two_boxes_description${number}`;
      const linkKey = `wst_two_boxes_link${number}`;
      const titleKey = `wst_two_boxes_title${number}`;
      const iconKey = `wst_two_boxes_icon_${number}`;
      return {
        buttonName: item[buttonKey] || '',
        linkButton: item[linkKey] || '',
        description: item[descriptionKey] || '',
        title: item[titleKey] || '',
        iconUrl: item[iconKey] || '',
      };
    });
  };

  const magazinesData = (data) => {
    const {
      wst_related_magazines_title,
      wst_related_magazines_group1,
      wst_related_magazines_group2,
    } = data;
    const title = wst_related_magazines_title || '';
    const firstMagazine = wst_related_magazines_group1 || {};
    const secondMagazine = wst_related_magazines_group2 || {};
    const group = [firstMagazine, secondMagazine];
    const items = group.map((item, index) => {
      const number = index + 1;
      const buttonKey = `wst_related_magazines_button${number}`;
      const titleKey = `wst_related_magazines_title${number}`;
      const linkKey = `wst_related_magazines_link${number}`;
      const coverKey = `wst_related_magazines_cover_${number}`;
      return {
        linkTitle: item[buttonKey] || '',
        linkTo: item[linkKey] || '',
        title: item[titleKey] || '',
        coverUrl: item[coverKey] || '',
      };
    });

    return {
      title: title,
      magazines: items,
    };
  };

  const joinStaffData = (data) => {
    const {
      wst_our_network_title,
      wst_our_network_link,
      wst_our_network_button,
      wst_our_network_background_image,
    } = data;
    const title = wst_our_network_title || '';
    const linkTitle = wst_our_network_button || '';
    const linkTo = wst_our_network_link || '';
    const imgUrl = wst_our_network_background_image || '';
    return {
      title: title,
      linkTitle: linkTitle,
      linkTo: linkTo,
      imgUrl: imgUrl,
    };
  };

  const bannerData = (data) => {
    const { wst_banner_link, wst_banner_image } = data;
    const linkTo = wst_banner_link || '';
    const imgUrl = wst_banner_image || '';
    return {
      linkTo: linkTo,
      imgUrl: imgUrl,
    };
  };

  const htmlData = (data) => {
    const { wst_html_text } = data;
    return {
      content: wst_html_text || '',
    };
  };

  return {
    headerData,
    latestPropertiesData,
    alertPropertiesData,
    testimonialsData,
    sellAndBuyData,
    magazinesData,
    joinStaffData,
    bannerData,
    buildPropsComponents,
  };
};

export default useBuilder;
