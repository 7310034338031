import React from 'react';
import Footer from '../globalComponents/Footer';
import PropertyDetails from '../sectionComponents/PropertyDetails';
import { Helmet } from 'react-helmet';
import HeaderFilters from './../globalComponents/HeaderFilters';

function PropertyDetailsScreen() {
  return (
    <div className="page-wrapper">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <HeaderFilters sticky={true} pageType={'properties'} />
      <div className="page-content bg-gray">
        <PropertyDetails />
        <Footer white />
      </div>
    </div>
  );
}

export default PropertyDetailsScreen;
