import React from 'react';
import MainCover from '../globalComponents/MainCover';
import CallToActionSubscribe from '../sectionComponents/CallToActionSubscribe';
import Magazines from '../sectionComponents/Magazines';
import WhatDoYouWant from '../sectionComponents/WhatDoYouWant';
import Ads from '../sectionComponents/Ads';
import LatestProperties from '../sectionComponents/LatestProperties';
import Comments from '../sectionComponents/Comments';
import AdImage from '../sectionComponents/AdImage';

export default function BuilderPage({ components }) {
  const componentsMappings = {
    cover: (props, index) => <MainCover imageUrl={props.imgUrl} title={props.title} key={index} />,
    banner: (props, index) => (
      <div className="container pt-5" key={index}>
        <AdImage imgUrl={props.imgUrl} url={props.linkTo} />
      </div>
    ),
    sellAndBuy: (props, index) => (
      <div className="pt-6" key={index}>
        <WhatDoYouWant cards={props} />
      </div>
    ),
    latestProperties: (props, index) => (
      <div className="container pt-6" key={index}>
        <LatestProperties title={props.title} typeId={props.typeId} />
      </div>
    ),
    testimonials: (props, index) => (
      <div className="container pt-6" key={index}>
        <Comments title={props.title} comments={props.comments} />
      </div>
    ),
    magazines: (props, index) => (
      <div className="container pt-6" key={index}>
        <Magazines title={props.title} magazines={props.magazines} />
      </div>
    ),
    joinStaff: (props, index) => (
      <Ads
        title={props.title}
        img={props.imgUrl}
        linkUrl={props.linkTo}
        linkTitle={props.linkTitle}
        key={index}
      />
    ),
    alertProperty: (props, index) => (
      <div className="container pt-6" key={index}>
        <CallToActionSubscribe
          title={props.title}
          description={props.description}
          buttonTitle={props.buttonName}
        />
      </div>
    ),
    html: (props, index) => {
      return props.content ? (
        <div className="container pt-6" key={index}>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      ) : (
        <div key={index}></div>
      );
    },
  };

  return (
    <>
      {components.map((component, index) => {
        const renderComponent = componentsMappings[component?.type];
        return renderComponent ? renderComponent(component?.props, index) : <div key={index}></div>;
      })}
    </>
  );
}
