import React from 'react';
import { BrowserRouter as Router, BrowserRouter, Route, Switch } from 'react-router-dom';
import AppContextProvider from './App.context';
import HomeScreen from './components/screens/HomeScreen';
import PropertiesListScreen from './components/screens/PropertiesListScreen';
import PropertyDetailsScreen from './components/screens/PropertyDetailsScreen';
import ContactUsScreen from './components/screens/ContactUsScreen';
import UnsubscribeScreen from './components/screens/UnsubscribeScreen';
import RealEstateSignUpScreen from './components/screens/RealEstateSignUpScreen';
import HomeSellersScreen from './components/screens/HomeSellersScreen';
import PageNotFound from './components/screens/PageNotFound';
import XmlFeed from './components/screens/XmlFeed';
import PropertiesAnalyze from './components/screens/PropertiesAnalyze';
import XmlFeedContextProvider from './XmlFeed.context';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <Router>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <BrowserRouter basename="/">
        <AppContextProvider>
          <XmlFeedContextProvider>
            <div data-testid="app">
              <Switch>
                <Route exact path="/" component={HomeScreen} />
                <Route exact path="/properties" component={PropertiesListScreen} />
                <Route exact path="/xmlFeed" component={XmlFeed} />
                <Route exact path="/analyze" component={PropertiesAnalyze} />
                <Route exact path="/property/:id" component={PropertyDetailsScreen} />
                <Route exact path="/forms/realEstateSignUp" component={RealEstateSignUpScreen} />
                <Route exact path="/forms/homeSellers" component={HomeSellersScreen} />
                <Route exact path="/forms/contactUs" component={ContactUsScreen} />
                <Route exact path="/unsubscribe/:subscriberId" component={UnsubscribeScreen} />
                <Route path="*" component={PageNotFound} />
              </Switch>
            </div>
          </XmlFeedContextProvider>
        </AppContextProvider>
      </BrowserRouter>
    </Router>
  );
}

export default App;
